import type { BarChartSeries } from "@mantine/charts";
import { BarChart } from "@mantine/charts";
import { Card, Group, Space, Text, Title } from "@mantine/core";
import { numericFormatter } from "react-number-format";
import { NoChartDataWrapper } from "../../../../mantine/charts/components/NoChartDataWrapper/NoChartDataWrapper";
import { RoundedNumberFormat } from "../../../../utils/RoundedNumberFormat";
import type { MantineBarChartPayloadItem } from "../../../Charts/BarChart/MantineBarChartTooltip";
import { MantineBarChartTooltip } from "../../../Charts/BarChart/MantineBarChartTooltip";
import type { EnergyRevenuesListItem } from "../../RevenueMonitoring.types";

const SERIES = [
  {
    label: "Marktprämie in EUR",
    name: "market_premium_revenue",
    color: "#42B8B2"
  },
  {
    label: "Direktvermarktung netto in EUR",
    name: "direktvermarktung_net_revenue",
    color: "#118EB0"
  }
] as BarChartSeries[];

const UNIT = " €";

interface RevenueOverviewChartProps {
  formattedData: Array<
    Pick<
      EnergyRevenuesListItem,
      | "direktvermarktung_net_revenue"
      | "market_premium_revenue"
      | "period_start"
    >
  >;
  totals: {
    direktvermarktung_net_revenue: number;
    market_premium_revenue: number;
  };
}

function RevenueOverviewChart({
  formattedData,
  totals
}: RevenueOverviewChartProps) {
  return (
    <Card w="100%" withBorder>
      <Title order={6}>Erlösübersicht</Title>
      <Text>
        Hier sehen Sie die Erlöse im ausgewählten Zeitraum, aufgeteilt nach
        Erlösen aus der Direktvermarktung abzüglich der
        Direktvermarktungsentgelte und der Marktprämie.
      </Text>
      <Space h="lg" />
      <Group align="center" wrap="nowrap">
        <NoChartDataWrapper noData={formattedData.length === 0}>
          <BarChart
            data={formattedData}
            dataKey="period_start"
            h={480}
            series={SERIES}
            tooltipProps={{
              content: ({ label, payload }) => {
                if (!payload?.length) return null;
                return (
                  <MantineBarChartTooltip
                    payload={[
                      ...(payload as MantineBarChartPayloadItem[]),
                      {
                        name: "total_revenue",
                        color: "#F6B93B",
                        value:
                          (payload?.[0]?.payload
                            ?.direktvermarktung_net_revenue || 0) +
                          (payload?.[0]?.payload?.market_premium_revenue || 0),
                        payload: {
                          category: "Gesamterlös in EUR"
                        }
                      }
                    ]}
                    seriesLabels={SERIES.reduce(
                      (result, singleSeries) => ({
                        ...result,
                        [singleSeries.name]:
                          singleSeries.label || singleSeries.name
                      }),
                      {}
                    )}
                    title={label}
                    valueFormatter={(value) =>
                      numericFormatter(value.toString(), {
                        decimalScale: 2,
                        decimalSeparator: ",",
                        thousandSeparator: "."
                      })
                    }
                  />
                );
              }
            }}
            type="stacked"
            unit={UNIT}
            valueFormatter={(value) =>
              numericFormatter((value / 1000).toString(), {
                decimalScale: 2,
                decimalSeparator: ",",
                suffix: "k",
                thousandSeparator: ".",
                fixedDecimalScale: true
              })
            }
            withLegend
          />
        </NoChartDataWrapper>
        <Card className="sidebar" miw={180} withBorder>
          <Text c="dimmed" size="xs">
            Gesamterlös
          </Text>
          <Text>
            <RoundedNumberFormat
              decimalScale={2}
              displayType="text"
              suffix={UNIT}
              value={
                totals.direktvermarktung_net_revenue +
                totals.market_premium_revenue
              }
            />
          </Text>
          <Space h="sm" />
          <Text c="dimmed" size="xs">
            Direktvermarktung
          </Text>
          <Text>
            <RoundedNumberFormat
              decimalScale={2}
              displayType="text"
              suffix={UNIT}
              value={totals.direktvermarktung_net_revenue}
            />
          </Text>
          <Space h="sm" />
          <Text c="dimmed" size="xs">
            Marktprämie
          </Text>
          <Text>
            <RoundedNumberFormat
              decimalScale={2}
              displayType="text"
              suffix={UNIT}
              value={totals.market_premium_revenue}
            />
          </Text>
        </Card>
      </Group>
    </Card>
  );
}

export { RevenueOverviewChart, RevenueOverviewChartProps };
