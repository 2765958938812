import { type BarChartSeries } from "@mantine/charts";
import { Group, type MantineColor, Paper, Text } from "@mantine/core";
import { numericFormatter } from "react-number-format";
import { getDefaultNumericFormatterProps } from "../../../utils/getDefaultNumericFormatterProps";

interface MantineBarChartPayloadItem {
  name: string;
  color: string;
  value: number;
  payload: {
    category: string;
  };
}

interface MantineBarChartTooltipProps {
  getBarColor?: (value: number, series: BarChartSeries) => MantineColor;
  labelFormatter?: (
    label: string,
    payload: Array<MantineBarChartPayloadItem>
  ) => React.ReactNode;
  seriesLabels: Record<string, string>;
  title: string;
  valueFormatter?: (
    value: number,
    name: string,
    item: MantineBarChartPayloadItem,
    index: number,
    payload: Array<MantineBarChartPayloadItem>
  ) => React.ReactNode;
  payload: Array<MantineBarChartPayloadItem>;
}

/*  Mantine doesn't allow changes to the recharts tooltip: https://mantine.dev/charts/line-chart/#custom-tooltip */
function MantineBarChartTooltip({
  getBarColor,
  labelFormatter,
  seriesLabels,
  title,
  valueFormatter,
  payload
}: MantineBarChartTooltipProps) {
  if (!payload) return null;

  const showsMultipleSeries = Array.isArray(payload) && payload.length > 1;

  return (
    <Paper
      className="MantineBarChart ChartTooltip"
      px="md"
      py="sm"
      radius="md"
      shadow="md"
      withBorder
    >
      {(showsMultipleSeries || labelFormatter) && (
        <Text fw={500} mb={5}>
          {labelFormatter ? labelFormatter(title, payload) : title}
        </Text>
      )}
      {Array.isArray(payload) &&
        payload.map((item, index) => (
          <Group justify="space-between" key={item.name}>
            <Group gap="sm">
              <div
                className="dot"
                style={{
                  backgroundColor:
                    getBarColor?.(item.payload[item.name], item) || item.color
                }}
              />
              <Text fz="sm">
                {!showsMultipleSeries
                  ? item.payload.category
                  : seriesLabels[item.name] ||
                    item.payload.category ||
                    "Unbekannt"}
              </Text>
            </Group>
            <Text fz="sm">
              {valueFormatter
                ? valueFormatter(item.value, item.name, item, index, payload)
                : numericFormatter(
                    item.value.toString(),
                    getDefaultNumericFormatterProps()
                  )}
            </Text>
          </Group>
        ))}
    </Paper>
  );
}

export { MantineBarChartPayloadItem, MantineBarChartTooltip };
